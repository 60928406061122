@keyframes bullet-fill {
  0% { width: 5%; }
  100% { width: 100%; }
}

#home-slider.home-slider {
  width: 100%;
  height: 685px;
  user-select: none;
  animation-duration: 5000ms;
  background-color: rgba(0,0,0,.1);
  margin-top: -130px;
  padding-top: 130px;


  [slot="container-start"], [slot="container-end"] {
    position: absolute;
    display: block;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  [slot="container-start"] { opacity: 1; }
  [slot="container-end"] {
    opacity: 0;
    &.animated {
      opacity: 1;
      transition: opacity .3s;
    }
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-pagination {
    animation-duration: inherit;

    .swiper-pagination-bullet {
      width: 30px;
      height: 5px;
      border-radius: 99px;
      opacity: 1;
      background-color: rgba(255, 255, 255, .2);
      
      &.swiper-pagination-bullet-active {
        position: relative;
        animation-duration: inherit;
  
        &:before {
          content: '';
          position: absolute;
          display: block;
          background-color: var(--white);
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          border-radius: 99px;
          animation-name: bullet-fill;
          animation-duration: inherit;
          animation-timing-function: linear;
        }
      }
    }
  }

  .swiper-button-next, .swiper-button-prev {
    width: 40px;
    height: 72px;
    background-color: rgba(0,0,0,.3);
    color: var(--white);
    &:after {
      font-size: 16px;
    }
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev {
    left: 0;
  }

  &.loop {
    .swiper-button-next, .swiper-button-prev {
      &.swiper-button-disabled {
        pointer-events: all !important;
        cursor: pointer !important;
        opacity: 1 !important;
      }
    }
  }

  .text-content {
    color: var(--white);
    text-align: left;
  }

  .slide-image {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center right;
    width: 100%;
    height: 420px;
    max-height: 420px;
  }

  .lg\:flex-row-reverse {
    .text-content {
      text-align: right;
    }
  
    .slide-image {
      background-position: center left;
    }
  }

  .slider-btn {
    color: var(--honolulu-blue);
    white-space: nowrap;
  }
}

@media(max-width: 1023px) {
  #home-slider.home-slider {
    margin-top: -75px;
    padding-top: 75px;
  
    .swiper-slide {
      justify-content: stretch;
      align-items: stretch;
    }
  
    @media(max-width: 991px) {
      .swiper-button-next, .swiper-button-prev {
        display: none;
      }
    }
  
    .text-content {
      text-align: center;
    }
  
    .media-content {
      display: flex;
      align-items: flex-end;
      max-width: 100%;
      height: 270px;

      :is(video), .plyr {
        width: 480px;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
      }
    }
  
    .slide-image {
      height: 240px;
      max-height: 240px;
      background-position: center center;
    }
  
    .lg\:flex-row-reverse {
      text-align: center;
      .text-content {
        text-align: center;
      }
    
      .slide-image {
        background-position: center center;
      }
    }
  }
}

@media(max-width: 991px) {
  #home-slider.home-slider {
    .swiper-button-next, .swiper-button-prev {
      display: none;
    }
  }
}
